<template>
  <div class="TaxPlatform">
    <p class="fs20">
      中再物贸云交易平台
    </p>
    <a-divider />
    <a-row>
      <a-col class="fs16">有色业务-魏总：13608486861</a-col>
      <a-col class="fs16">废钢业务-张总：18037495666</a-col>
      <a-col class="fs16">煤炭业务-罗总：19103838333</a-col>
      <a-col class="fs16">客服电话：0371-87089899</a-col>
    </a-row>
    <a-row>
      <a-col class="fs16">地址：河南省郑州市航空港区洵美路兴瑞汇金国际A5座</a-col>

      <a-col class="fs16">
        <baidu-map id="allmap" @ready="mapReady"></baidu-map>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  methods: {
    mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(113.827162,34.46451);
      map.centerAndZoom(this.point, 12);
      //添加鼠标滚动缩放
      map.enableScrollWheelZoom()
      //添加缩略图控件
      map.addControl(new BMap.OverviewMapControl({ isOpen: false, anchor: 'BMAP_ANCHOR_BOTTOM_RIGHT' }));
      //添加缩放平移控件
      map.addControl(new BMap.NavigationControl());
      //添加比例尺控件
      map.addControl(new BMap.ScaleControl());
      var marker = new BMap.Marker(this.point);  // 创建标注
      map.addOverlay(marker);              // 将标注添加到地图中
      //把标注添加到地图上
      var opts = {
        width: 200,     // 信息窗口宽度
        height: 100,     // 信息窗口高度
        title: "中再物产有限公司", // 信息窗口标题
      }
      var infoWindow = new BMap.InfoWindow("地址：河南省郑州市航空港区洵美路兴瑞汇金国际A5座", opts);  // 创建信息窗口对象 
      marker.addEventListener("click", function () {
        map.openInfoWindow(infoWindow, this.point); //开启信息窗口
      });
      map.addOverlay(marker);
    },
  }
}
</script>

<style lang="less" scoped>
.TaxPlatform {
  background-color: #fff;
  width: 1190px;
  padding: 40px;
}
.fs20 {
  font-size: 20px;
  color: rgb(102, 102, 102);
}
.fs16 {
  font-size: 16px;
  line-height: 28px;
  color: #aaa;
  img {
    margin-top: 20px;
  }
}
.ant-row {
  padding-bottom: 40px;
}
#allmap {
  margin-top: 20px;
  height: 300px;
  width: 900px;
  @media screen and (max-width: 1440px){
    width: 80%;
  }
}
</style>